import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './Form.module.scss';
import classNames from 'classnames';

const Form = ({columns, inline = false, dark=false, onSubmit, children}) => {
    const formClasses = useMemo(() => {
        let classes = [styles.Form];
        if(columns){
            classes.push(styles['formColumns'+columns]);
        }

        if(inline){
            classes.push(styles.inline);
        }

        if(dark){
            classes.push(styles.dark);
        }
        return classes;
    }, [columns, inline]);

    return (
        <form className={classNames(formClasses)} autoComplete={'off'} onSubmit={onSubmit}>
            {children}
        </form>
    );
};

Form.propTypes = {};

export default Form;
