import classNames from "classnames";
import AnimatedText from "components/AnimatedText/AnimatedText.js";
import BandIcon from "components/BandIcon/BandIcon.js";
import Duck from "components/Duck/Duck.js";
import HeroButton, {
    HERO_BUTTON_COLOR_GREEN,
} from "components/HeroButton/HeroButton.js";
import NumbersLogo from "components/NumbersLogo/NumbersLogo.js";
import {
    DUCK_RIDDLE_ROUTE, HUB_ROUTE,
    NUMBERS_RIDDLE_ROUTE,
    RAINBOW_RIDDLE_ROUTE,
    SONG_RIDDLE_ROUTE,
} from "constants/appRoutes.js";
import { LOCK_ONE_SOLUTION, LOCK_THREE_SOLUTION, LOCK_TWO_SOLUTION } from "constants/solutions.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import Lock from "views/Hub/Lock/Lock.js";
import IntroText from "views/Intro/IntroText.js";
import styles from "../Hub/Hub.module.scss";

const Intro = props => {
    const history = useHistory();
    const [showLocks, setShowLocks] = useState(false);
    const [showHeroButton, setShowHeroButton] = useState(false);
    const [showHeroButtonWrapper, setShowHeroButtonWrapper] = useState(false);

    const [showLinks, setShowLinks] = useState(false);
    const [textStartIndex, setTextStartIndex] = useState(0);
    const [textEndIndex, setTextEndIndex] = useState(0);

    const {setIntroWatched, introWatched} = useContext(UserProgressContext);

    useEffect(() => {
        if(introWatched){
            history.replace(HUB_ROUTE)
        }
    }, [introWatched]);

    useEffect(() => {
        if (!showLocks && !showHeroButtonWrapper) {
            setTextStartIndex(0);
            setTextEndIndex(5);
        } else if (showLocks && !showHeroButtonWrapper) {
            setTextStartIndex(6);
            setTextEndIndex(7);
        }
        else if (!showLinks) {
            setTextStartIndex(8);
            setTextEndIndex(8);
        } else {
            setTextStartIndex(9);
            setTextEndIndex(null);
        }
    }, [showLocks,
        showHeroButtonWrapper,
        showLinks,
    ]);

    const handleTextBlockEnd = () => {
        if (!showLocks && !showHeroButtonWrapper) {
            setShowLocks(true);
        } else if (showLocks && !showHeroButtonWrapper) {
            setShowHeroButtonWrapper(true);
        }
        else if (!showLinks) {
            setShowLinks(true);
        }
        else{
            setIntroWatched(true);
        }
    };

    const handleShowHeroButtonAnimationEnd = (event) => {
        setShowHeroButton(true)
    };

    return (
        <div className={ classNames(styles.Hub, styles.Intro) }>
            <div className={ styles.IntroTextWrapper }>
                <AnimatedText textDefinitions={ IntroText } fadeOutLastText={ true } startIndex={ textStartIndex }
                              endIndex={ textEndIndex }
                              onFinished={ handleTextBlockEnd }/>
            </div>
            <div className={ styles.FlexWrapper }>
                { showLinks && <div className={ styles.IconWrapper }>
                    <NavLink to={ RAINBOW_RIDDLE_ROUTE }>
                        <div className={ styles.FlagIcon }/>
                    </NavLink>
                </div> }
                { showLinks &&
                <div className={ styles.IconWrapper }>
                    <NavLink to={ DUCK_RIDDLE_ROUTE }><Duck className={ styles.DuckIcon }/></NavLink>
                </div> }
                { showHeroButtonWrapper &&
                <div className={ styles.HeroButtonWrapper } onAnimationEnd={ handleShowHeroButtonAnimationEnd }>
                    { showHeroButton && <HeroButton className={ styles.HeroButton }
                                                    color={ HERO_BUTTON_COLOR_GREEN } fadeIn={ true }
                    /> }
                </div> }
                { showLinks && <div className={ styles.IconWrapper }>
                    <NavLink to={ NUMBERS_RIDDLE_ROUTE }><NumbersLogo className={ styles.NumberIcon }/></NavLink>
                </div> }
                { showLinks &&
                <div className={ styles.IconWrapper }>
                    <NavLink to={ SONG_RIDDLE_ROUTE }><BandIcon className={ styles.BandIcon }/></NavLink>
                </div> }
            </div>
            { showLocks && <div className={ styles.FlexWrapper }>
                <Lock fadeIn={ true } colors={ LOCK_ONE_SOLUTION } nextColorIndex={ 0 } showColorHint={ true }
                      solved={ false } disabled={ false }/>
                <Lock fadeIn={ true } colors={ LOCK_TWO_SOLUTION } nextColorIndex={ 0 }
                      solved={ false } disabled={ true }/>
                <Lock fadeIn={ true } colors={ LOCK_THREE_SOLUTION } nextColorIndex={ 0 } solved={ false }
                      disabled={ true }/>
                <Lock fadeIn={ true } colors={ LOCK_THREE_SOLUTION } nextColorIndex={ 0 } solved={ false }
                      disabled={ true }/>
            </div> }
        </div>
    );
};

Intro.propTypes = {};

export default Intro;
