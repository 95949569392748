import Button from "components/Button";
import { NUMBERS_RIDDLE_SOLUTION } from "constants/solutions.js";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import NumberWrapper from "views/NumbersRiddle/NumbersForm/NumberWrapper/NumberWrapper.js";
import styles from "./NumbersForm.module.scss";

const NumbersForm = ({onSolved}) => {
    const { register, handleSubmit, watch } = useForm({ mode: "onChange" });
    const fieldVales = watch();

    useEffect(() => {
        if(Object.values(fieldVales).join("") === NUMBERS_RIDDLE_SOLUTION){
            onSolved();
        }
    }, [fieldVales]);

    return (
        <form id="colorForm" className={ styles.NumbersForm } onSubmit={ handleSubmit }>
            <NumberWrapper color="green">
                <input type="text" maxLength="1" placeholder="?" { ...register("inputGreen") }/>
            </NumberWrapper>
            <NumberWrapper color="blue">
                <input type="text" maxLength="1" placeholder="?" { ...register("inputBlue") }/>
            </NumberWrapper>
            <NumberWrapper color="orange">
                <input type="text" maxLength="1" placeholder="?" { ...register("inputOrange") }/>
            </NumberWrapper>
            <NumberWrapper color="red">
                <input type="text" maxLength="1" placeholder="?" { ...register("inputRed") }/>
            </NumberWrapper>
            <NumberWrapper color="purple">
                <input type="text" maxLength="1" placeholder="?" { ...register("inputPurple") }/>
            </NumberWrapper>
        </form>
    );
};

NumbersForm.propTypes = {};

export default NumbersForm;
