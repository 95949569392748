import classNames from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Lock.module.scss";

const Lock = ({ colors, nextColorIndex, showColorHint = false, solved = false, disabled = false, animate=false }) => {
    const circles = useMemo(() => {
        return colors.map((color, index) => {
            let classList = classNames(styles.LockCircle, styles.solved, styles[colors[index]]);

            return <div key={ index }
                        className={ classList }/>;
        });
    }, [colors, showColorHint, nextColorIndex]);

    return (
        <div className={ classNames(styles.LockWrapper, {[styles.animate]:animate}) }>
            <div className={ classNames(styles.Lock, { [styles.disabled]: disabled, [styles.solved]: solved }) }>
                { circles }
            </div>
        </div>
    );
};

Lock.propTypes = {};

export default Lock;
