import AnimatedText from "components/AnimatedText/AnimatedText.js";
import Content from "components/Layout/Content/Content.js";
import Terminal from "components/Terminal/Terminal.js";
import { HUB_ROUTE, MORSE_RIDDLE_ROUTE } from "constants/appRoutes.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import WelcomeText from "./WelcomeText.js";
import styles from "./Welcome.module.scss";

const Welcome = props => {
    const history = useHistory();
    const {morseSolved} = useContext(UserProgressContext);

    useEffect(() => {
        if(morseSolved){
            history.replace(HUB_ROUTE)
        }
    }, [morseSolved]);

    const handleTextAnimationFinished = ()=>{
        history.push(MORSE_RIDDLE_ROUTE)
    }

    return (
        <Content>
            <div className={ styles.Welcome }>
                <AnimatedText onFinished={handleTextAnimationFinished} textDefinitions={WelcomeText}/>
            </div>
        </Content>
    );
};

Welcome.propTypes = {};

export default Welcome;
