import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "components/HeroButton/HeroButton.module.scss";
import useAsyncEffect from "use-async-effect";

export const HERO_BUTTON_COLOR_RED = "red";
export const HERO_BUTTON_COLOR_ORANGE = "orange";
export const HERO_BUTTON_COLOR_YELLOW = "yellow";
export const HERO_BUTTON_COLOR_GREEN = "green";
export const HERO_BUTTON_COLOR_BLUE = "blue";
export const HERO_BUTTON_COLOR_PURPLE = "purple";
export const HERO_BUTTON_COLOR_BLACK = "black";

export const HERO_BUTTON_SIZE_SMALL = "s";
export const HERO_BUTTON_SIZE_MEDIUM = "m";
export const HERO_BUTTON_SIZE_LARGE = "l";

const HeroButton = React.forwardRef(({
                                         className,
                                         color,
                                         size = "m",
                                         disabled,
                                         type = "button",
                                         children,
                                         rest,
                                         onClick,
                                         onMouseDown,
                                         onMouseUp,
                                         onTouchStart,
                                         onTouchEnd,
                                         wrapperClassName,
                                         fadeIn = false,
                                         fadeOut = false,
                                         grow = false,
                                         pulse = false,
                                         onAnimationEnd,
                                     }, ref) => {
    const props = {};


    if (onClick) {
        props.onClick = onClick;
    }

    if (onMouseDown) {
        props.onMouseDown = onMouseDown;
    }

    if (onMouseUp) {
        props.onMouseUp = onMouseUp;
    }

    if (onTouchStart) {
        props.onTouchStart = onTouchStart;
    }

    if (onTouchEnd) {
        props.onTouchEnd = onTouchEnd;
    }

    return (
        <div
            className={ classNames(styles.HeroButton, styles[`btn-size-${ size }`], wrapperClassName, {
                [styles.fadeIn]: fadeIn,
                [styles.fadeOut]: fadeOut,
                [styles.grow]: grow,
                [styles.pulse]: pulse,
            }) }
            onAnimationEnd={ onAnimationEnd }
        >
            <button
                className={ classNames(
                    styles.InnerButton,
                    styles[color],
                    { [styles.disabled]: disabled },
                    className,
                ) }
                disabled={ disabled }
                type={ type }
                { ...props }
                { ...rest }
                ref={ ref }
            >
                { children }
            </button>
        </div>
    );
});

HeroButton.propTypes = {};

export default HeroButton;
