export const NUMBERS_RIDDLE_SOLUTION = "69723";

export const HINT_RIDDLE_SOLUTION = "392";

export const DUCK_RIDDLE_SOLUTION = ["green", "green", "blue", "red", "purple", "orange"];


export const LOCK_ONE_SOLUTION = ["green", "red", "green"];
export const LOCK_TWO_SOLUTION = ["purple", "blue", "green"]; // from numbers -> 396
export const LOCK_THREE_SOLUTION = ["orange", "blue", "purple"]; // from duck -> notes d,e,a
export const LOCK_FOUR_SOLUTION = ["green", "blue", "black"]; // from song  left middle right

