import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Field.module.scss";
import classNames from "classnames";
import Label from "../Label/Label";
import InputPreElement from "../InputPreElement/InputPreElement";
import { getViewModeValue } from "../../../utils/formUtils";

const Field = React.forwardRef(
	(
		{
			className = [],
			name,
			type,
			label,
			defaultValue,
			error,
			preElementText = false,
			selectOptions = [],
			viewMode = false,
			inline = false,
			children,
			...other
		},
		ref,
	) => {
		let inputElement;
		const fieldClassNames = useMemo(() => {
			return inline === true ? [styles.Field, styles.inline] : [styles.Field];
		}, [inline]);

		switch (type) {
			case "text":
			case "password":
				if (!viewMode) {
					inputElement = (
						<React.Fragment>
							{preElementText && <InputPreElement text={preElementText} />}
							<input
								className={classNames(className, preElementText && styles.hasPreElement)}
								type={type}
								name={name}
								ref={ref}
								{...other}
							/>
						</React.Fragment>
					);
				} else {
					inputElement = <div className={styles.ViewModeValue}>{getViewModeValue(defaultValue)}</div>;
				}

				break;
			case "textarea":
				if (!viewMode) {
					inputElement = <textarea className={classNames(className)} name={name} ref={ref} {...other} />;
				} else {
					inputElement = <div className={styles.ViewModeValue}>{getViewModeValue(defaultValue)}</div>;
				}
				break;
			case "select":
				if (!viewMode) {
					inputElement = (
						<select className={classNames(className)} name={name} ref={ref} {...other}>
							{children}
						</select>
					);
				} else {
					inputElement = (
						<div className={styles.ViewModeValue}>
							{getViewModeValue(defaultValue, "select", selectOptions)}
						</div>
					);
				}

				break;
			case "checkbox":
				inputElement = (
					<div className={classNames(className)}>
						<input type={"checkbox"} name={name} ref={ref} {...other} />
						<div>{children}</div>
					</div>
				);
				break;
			default:
				throw new Error("Invalid Field type: " + type);
		}

		return (
			<label
				className={classNames([
					fieldClassNames,
					styles[type],
					error && styles.invalid,
					viewMode && styles.viewMode,
				])}
			>
				{label && <Label text={label} className={styles.Label} />}
				<div className={styles.inputWrapper}>
					{inputElement}
					{error && <div className={styles.Error}>{error}</div>}
				</div>
			</label>
		);
	},
);

Field.defaultValues = {};

Field.propTypes = {
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			defaultValue: PropTypes.string,
			displayValue: PropTypes.string,
		}),
	),
};

Field.whyDidYouRender = true;

export default Field;
