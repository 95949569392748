import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader.js";
import styles from "./SubmitButton.module.scss";

const SubmitButton = ({children, loading, disabled, className}) => {
    if(loading){
        children = <PulseLoader size={'6px'} color={'#fff'}/>
    }

    return (
        <button type="submit" className={classNames(styles.SubmitButton, className)} disabled={disabled}>
            {children}
        </button>
    );
};

SubmitButton.propTypes = {};

export default SubmitButton;
