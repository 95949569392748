import { advanceProgress } from "api/loaders/userProgressLoaders.js";
import ConditionalRedirects from "components/ConditionalRedirects/ConditionalRedirects.js";
import CrtScreen from "components/CrtScreen/CrtScreen.js";
import { ROLE_PARTY_GUEST, ROLE_RIDDLER } from "constants/roles.js";
import { AuthContext } from "contexts/AuthContext";
import { UserProgressContextProvider } from "contexts/UserProgressContext";
import { parse } from "query-string";
import React, { useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Layout from "components/Layout/Layout.js";
import {
    DUCK_RIDDLE_ROUTE, FINAL_SCREEN_ROUTE,
    HOME_ROUTE,
    HUB_ROUTE, INTRO_ROUTE, LOGIN_ROUTE,
    MORSE_RIDDLE_ROUTE,
    NUMBERS_RIDDLE_ROUTE,
    RAINBOW_RIDDLE_ROUTE, SIGNUP_ROUTE,
    SONG_RIDDLE_ROUTE, START_OVER_SCREEN_ROUTE,
    STORYBOOK_ROUTE, WELCOME_ROUTE,
} from "constants/appRoutes.js";
import useAsyncEffect from "use-async-effect";
import { getCurrentStep, getRouteByStepId } from "utils/userProgressUtils.js";
import DuckRiddle from "views/DuckRiddle/DuckRiddle.js";
import FinalScreen from "views/FinalScreen/FinalScreen.js";
import Hub from "views/Hub/Hub.js";
import Intro from "views/Intro/Intro.js";
import Login from "views/Login/Login.js";
import MorseRiddle from "views/MorseRiddle/MorseRiddle.js";
import NumbersRiddle from "views/NumbersRiddle/NumbersRiddle.js";
import RainbowRiddle from "views/RainbowRiddle/RainbowRiddle.js";

import SignUp from "views/SignUp/SignUp.js";
import SongRiddle from "views/SongRiddle/SongRiddle.js";
import StartOverConfirm from "views/StartOverConfirm/StartOverConfirm.js";
import Storybook from "views/Storybook/Storybook.js";
import Welcome from "views/Welcome/Welcome.js";

const AppRouter = props => {
    const { user, isCheckingAuthStatus, initialProgress } = useContext(AuthContext);

    return !isCheckingAuthStatus ? (
        <UserProgressContextProvider initState={ initialProgress }>
            <Layout>
                { user ? (
                        <Switch>
                            {/*<Route exact path={ STORYBOOK_ROUTE } component={ CrtScreen }/>*/ }
                            <Route exact path={ INTRO_ROUTE } component={ Intro }/>
                            <Route exact path={ WELCOME_ROUTE } component={ Welcome }/>
                            <Route exact path={ HUB_ROUTE } component={ Hub }/>
                            <Route exact path={ NUMBERS_RIDDLE_ROUTE } component={ NumbersRiddle }/>
                            <Route exact path={ RAINBOW_RIDDLE_ROUTE } component={ RainbowRiddle }/>
                            <Route exact path={ MORSE_RIDDLE_ROUTE } component={ MorseRiddle }/>
                            <Route exact path={ SONG_RIDDLE_ROUTE } component={ SongRiddle }/>
                            <Route exact path={ DUCK_RIDDLE_ROUTE } component={ DuckRiddle }/>
                            <Route exact path={ FINAL_SCREEN_ROUTE } component={ FinalScreen }/>
                            <Route exact path={ START_OVER_SCREEN_ROUTE } component={ StartOverConfirm }/>
                            <Route
                                render={ () => {
                                    return <Redirect to={ WELCOME_ROUTE }/>;
                                } }
                            />
                        </Switch>
                ) : (
                    <Switch>
                        <Route exact path={ LOGIN_ROUTE } component={ Login }/>
                        <Route exact path={ SIGNUP_ROUTE } component={ SignUp }/>
                        <Route
                            render={ () => {
                                return <Redirect to={ LOGIN_ROUTE }/>;
                            } }
                        />
                    </Switch>
                ) }
            </Layout>
        </UserProgressContextProvider>
    ) : (
        ""
    );
};

AppRouter.propTypes = {};

export default AppRouter;
