import SubmitButton from "components/SubmitButton/SubmitButton.js";
import TextInput from "components/TextInput/TextInput.js";
import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import styles from "./SongForm.module.scss";

const SongForm = ({onSolved}) => {
    const { handleSubmit, register } = useForm({})

    const onSubmit = (formData) => {
        const input = formData.band.trim().toLowerCase().replaceAll(' ', '');
        if(input === 'redhotchilipeppers' || input === 'redhotchillipeppers'){
            onSolved();
        }
    }

    return (
        <form className={styles.SongForm} onSubmit={ handleSubmit(onSubmit) }>
            <TextInput type="text" { ...register('band') }/>
            <SubmitButton>Diese Band!</SubmitButton>
        </form>
    );
};

SongForm.propTypes = {};

export default SongForm;
