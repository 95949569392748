export const newWordThreshold = 1000;
export const longPressThreshold = 200;

export const alphabet = {
    "-----": "0",
    "·----": "1",
    "··---": "2",
    "···--": "3",
    "····-": "4",
    "·····": "5",
    "-····": "6",
    "--···": "7",
    "---··": "8",
    "----·": "9",
    "·-": "a",
    "-···": "b",
    "-·-·": "c",
    "-··": "d",
    "·": "e",
    "··-·": "f",
    "--·": "g",
    "····": "h",
    "··": "i",
    "·---": "j",
    "-·-": "k",
    "·-··": "l",
    "--": "m",
    "-·": "n",
    "---": "o",
    "·--·": "p",
    "--·-": "q",
    "·-·": "r",
    "···": "s",
    "-": "t",
    "··-": "u",
    "···-": "v",
    "·--": "w",
    "-··-": "x",
    "-·--": "y",
    "--··": "z",
    "/": " ",
    "-·-·--": "!",
    "·-·-·-": ".",
    "--··--": ",",
};
