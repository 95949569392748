import HeroButton, { HERO_BUTTON_COLOR_RED } from "components/HeroButton/HeroButton.js";
import Content from "components/Layout/Content/Content.js";
import { WELCOME_ROUTE } from "constants/appRoutes.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styles from "./StartOverConfirm.module.scss";

const StartOverConfirm = props => {
    const {startOver} = useContext(UserProgressContext)
    const history = useHistory();
    const handleResetClick = ()=>{
        startOver();
        history.push(WELCOME_ROUTE);
    }
    return (
        <Content>
            <div className={styles.StartOverConfirm}>
                <h1>Willst du wirklich von Vorne anfangen? <br/>Dann drück auf den Knopf!</h1>
                <HeroButton size="l" color={HERO_BUTTON_COLOR_RED} onClick={handleResetClick}/>

            </div>
        </Content>
    );
};

StartOverConfirm.propTypes = {};

export default StartOverConfirm;
