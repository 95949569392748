import axiosApi from "api/axiosApi.js";
import {
    loginEndpoint,
    logoutEndpoint,
    getAuthenticatedUserEndpoint,
    getCsrfTokenEndpoint,
    loginWithTokenEndpoint, validateInvitationTokenEndpoint, createUserEndpoint,
} from "api/endpoints/authEndpoints.js";

export const getCsrfToken = () => axiosApi.get(getCsrfTokenEndpoint);
export const login = (username, password) => axiosApi.post(loginEndpoint, { username: username, password: password });
export const loginWithToken = token => axiosApi.post(loginWithTokenEndpoint, { token: token });
export const logout = () => axiosApi.get(logoutEndpoint);
export const getAuthenticatedUser = () => axiosApi.get(getAuthenticatedUserEndpoint);
export const validateInvitationtoken = invitationToken => axiosApi.post(validateInvitationTokenEndpoint, { invitationToken });
export const createUser = (username, password, invitationToken)=>axiosApi.post(createUserEndpoint, {username, password, invitationToken})
