import classNames from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./MiniLock.module.scss";

const MiniLock = props => {
    return (
        <div className={ styles.LockWrapper }>
            <div className={ styles.Lock }>
                <div className={ styles.LockCircle }/>
                <div className={ styles.LockCircle }/>
                <div className={ styles.LockCircle }/>
            </div>
        </div>
    );
};

MiniLock.propTypes = {};

export default MiniLock;
