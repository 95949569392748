import { updateProgress } from "api/loaders/userProgressLoaders.js";
import { LOCK_FOUR_SOLUTION, LOCK_ONE_SOLUTION, LOCK_THREE_SOLUTION, LOCK_TWO_SOLUTION } from "constants/solutions.js";
import React, { useCallback, useState } from "react";
import useAsyncEffect from "use-async-effect";


import { UserProgressContext } from "./UserProgressContext.js";

export const UserProgressContextProvider = ({ children, initState }) => {
    const [savingProgress, setSavingProgress] = useState(false);
    const [savingProgressFailed, setSavingProgressFailed] = useState(false);
    // Morse
    const [morseSolved, setMorseSolved] = useState(false);
    // Hint Remote
    const [hintRiddleSolved, setHintRiddleSolved] = useState(false);
    const [showHints, setShowHints] = useState(false);
    // RainbowRiddle
    const [redBarIsUnlocked, setRedBarIsUnlocked] = useState(false);
    const [rainbowSolved, setRainbowSolved] = useState(false);
    const [duckHintSolved, setDuckHintSolved] = useState(false);
    // Numbers Riddle
    const [numbersRiddleSolved, setNumbersRiddleSolved] = useState(false);
    // Song Riddle
    const [songRiddleSolved, setSongRiddleSolved] = useState(false);
    // Duck Riddle
    const [duckRiddleSolved, setDuckRiddleSolved] = useState(false);
    // Locks
    const [currentLockIndex, setCurrentLockIndex] = useState(0);
    const [currentLockNextColorIndex, setCurrentLockNextColorIndex] = useState(0);
    const [allSolved, setAllSolved] = useState(false);

    // Intros
    const [introWatched, setIntroWatched] = useState(false);

    const [wasSolvedBefore, setWasSolvedBefore] = useState(false)

    useAsyncEffect(async () => {
        // console.log('setting initial state');
        // setMorseSolved(true)
        // setHintRiddleSolved(true)
        // setRedBarIsUnlocked(true)
        // setRainbowSolved(true)
        // setDuckHintSolved(true)
        // setNumbersRiddleSolved(true)
        // setSongRiddleSolved(true)
        // setDuckRiddleSolved(true)
        // setCurrentLockIndex(3)
        // setCurrentLockNextColorIndex(2)
        // setAllSolved(true)
        // setIntroWatched(true)
        // setWasSolvedBefore(false);
        // setShowHints(false)
        // return;
        if (!initState) {
            setMorseSolved(false)
            setHintRiddleSolved(false)
            setRedBarIsUnlocked(false)
            setRainbowSolved(false)
            setDuckHintSolved(false)
            setNumbersRiddleSolved(false)
            setSongRiddleSolved(false)
            setDuckRiddleSolved(false)
            setCurrentLockIndex(0)
            setCurrentLockNextColorIndex(0)
            setAllSolved(false)
            setIntroWatched(false)
            setWasSolvedBefore(false);
        }
        else{
            setMorseSolved(initState.morseSolved)
            setHintRiddleSolved(initState.hintRiddleSolved)
            setRedBarIsUnlocked(initState.redBarIsUnlocked)
            setRainbowSolved(initState.rainbowSolved)
            setDuckHintSolved(initState.duckHintSolved)
            setNumbersRiddleSolved(initState.numbersRiddleSolved)
            setSongRiddleSolved(initState.songRiddleSolved)
            setDuckRiddleSolved(initState.duckRiddleSolved)
            setCurrentLockIndex(initState.currentLockIndex)
            setCurrentLockNextColorIndex(initState.currentLockNextColorIndex)
            setAllSolved(initState.allSolved)
            setIntroWatched(initState.introWatched)
            setWasSolvedBefore(initState.wasSolvedBefore)
        }
    }, []);

    const startOver = useCallback(() => {
        setMorseSolved(false)
        setHintRiddleSolved(false)
        setRedBarIsUnlocked(false)
        setRainbowSolved(false)
        setDuckHintSolved(false)
        setNumbersRiddleSolved(false)
        setSongRiddleSolved(false)
        setDuckRiddleSolved(false)
        setCurrentLockIndex(0)
        setCurrentLockNextColorIndex(0)
        setAllSolved(false)
        setIntroWatched(false)
        setWasSolvedBefore(true)
    }, []);

    const processLockButtonClick = useCallback((color) => {
        const lockSolutions = [LOCK_ONE_SOLUTION, LOCK_TWO_SOLUTION, LOCK_THREE_SOLUTION, LOCK_FOUR_SOLUTION];

        if (color === lockSolutions[currentLockIndex][currentLockNextColorIndex]) {
            if (currentLockNextColorIndex + 1 === 3) {
                if (currentLockIndex + 1 === lockSolutions.length) {
                    setAllSolved(true);
                }
                setCurrentLockIndex(prevState => prevState + 1);
                setCurrentLockNextColorIndex(0);
            } else {
                setCurrentLockNextColorIndex(prevState => prevState + 1);
            }
        } else {
            setCurrentLockNextColorIndex(0);
        }
    }, [currentLockIndex, currentLockNextColorIndex, setCurrentLockNextColorIndex, setAllSolved]);

    useAsyncEffect(async () => {
        setSavingProgress(true);
        console.log('saving progress');
        try {
            const response = await updateProgress({
                progress: {
                    morseSolved,
                    hintRiddleSolved,
                    redBarIsUnlocked,
                    rainbowSolved,
                    duckHintSolved,
                    numbersRiddleSolved,
                    songRiddleSolved,
                    duckRiddleSolved,
                    currentLockIndex,
                    currentLockNextColorIndex,
                    allSolved,
                    wasSolvedBefore,
                    introWatched
                },
            });
        } catch (error) {
            console.log(error);
            setSavingProgressFailed(true);
        }

        setSavingProgress(false);
    }, [morseSolved,
        hintRiddleSolved,
        redBarIsUnlocked,
        rainbowSolved,
        duckHintSolved,
        numbersRiddleSolved,
        songRiddleSolved,
        duckRiddleSolved,
        currentLockIndex,
        currentLockNextColorIndex,
        wasSolvedBefore,
        allSolved,
    introWatched]);

    return (
        <UserProgressContext.Provider
            value={ {
                savingProgress,
                savingProgressFailed,
                showHints,
                setShowHints,
                hintRiddleSolved,
                setHintRiddleSolved,
                morseSolved,
                setMorseSolved,
                rainbowSolved,
                setRainbowSolved,
                redBarIsUnlocked,
                setRedBarIsUnlocked,
                duckHintSolved,
                setDuckHintSolved,
                numbersRiddleSolved,
                setNumbersRiddleSolved,
                songRiddleSolved,
                setSongRiddleSolved,
                duckRiddleSolved,
                setDuckRiddleSolved,
                currentLockIndex,
                currentLockNextColorIndex,
                processLockButtonClick,
                allSolved,
                introWatched,
                setIntroWatched,
                setWasSolvedBefore,
                wasSolvedBefore,
                startOver
            } }
        >
            { children }
        </UserProgressContext.Provider>
    );
};
