const WelcomeText = [{ text: "Hallo", duration: 2000 }, {
    text: "Magst Du Rätsel?",
    duration: 2000,
},
    { text: "Dumme Frage, jeder mag Rätsel...", duration: 2500 },
    { text: "Je schwieriger, desto besser", duration: 2000 },
    { text: "Aber wir fangen erstmal einfach an", duration: 3000 }
];

export default WelcomeText;
