import { FINAL_SCREEN_ROUTE } from "constants/appRoutes.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styles from "./ConditionalRedirects.module.scss";

const ConditionalRedirects = ({ children }) => {
    const history = useHistory();
    const { allSolved } = useContext(UserProgressContext)

    useEffect(() => {
        if(allSolved){
            history.push(FINAL_SCREEN_ROUTE)
        }
    }, [history, allSolved]);
    return children;
};

ConditionalRedirects.propTypes = {};

export default ConditionalRedirects;
