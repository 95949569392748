import classNames from "classnames";
import HeroButton, {
    HERO_BUTTON_COLOR_RED,
} from "components/HeroButton/HeroButton.js";
import { UserProgressContext } from "contexts/UserProgressContext";

import React, { useContext, useRef, useState } from "react";

import { alphabet, longPressThreshold, newWordThreshold } from "utils/morseUtils.js";
import styles from "./MorseButton.module.scss";

const solution = ["r", "o", "t"];
// const solution = ["e", "e", "e"];

const MorseButton = ({ onSolved, fadeInMorseButton }) => {
    const { processLockButtonClick } = useContext(UserProgressContext);
    const timeLastMouseUp = useRef(null);
    const timeLastMouseDown = useRef(null);
    const newWordTimeOut = useRef(null);
    const currentLetter = useRef("");
    const [lastLetterMorse, setLastLetterMorse] = useState("");
    const [nextSolutionLetterIndex, setNextSolutionLetterIndex] = useState(0);
    const [morseCodeSolved, setMorseCodeSolved] = useState(false);

    const handleMouseDownEvent = (event) => {
        event.preventDefault();
        timeLastMouseDown.current = new Date().getTime();
        clearTimeout(newWordTimeOut.current);
    };

    const handleMouseUpEvent = () => {
        event.preventDefault();
        const mouseUpTime = new Date().getTime();
        let detectedMorseInput = "·";
        if (mouseUpTime - timeLastMouseDown.current > longPressThreshold) {
            detectedMorseInput = "-";
        }
        processLockButtonClick("red");
        currentLetter.current += detectedMorseInput;
        setLastLetterMorse(currentLetter.current);
        timeLastMouseUp.current = mouseUpTime;
        newWordTimeOut.current = setTimeout(() => {
            const detectedLetter = alphabet[currentLetter.current];
            let solved = false;
            if (detectedLetter && solution[nextSolutionLetterIndex] && solution[nextSolutionLetterIndex].toLowerCase() === detectedLetter) {
                if (nextSolutionLetterIndex + 1 === solution.length) {
                    solved = true;
                } else {
                    setNextSolutionLetterIndex(prevState => prevState + 1);
                }
            } else {
                setNextSolutionLetterIndex(0);
            }

            if (solved) {
                setLastLetterMorse("");
                setMorseCodeSolved(true);
                // onSolved();
            } else {
                currentLetter.current = "";
                setLastLetterMorse("");
            }
        }, newWordThreshold);
    };

    return (
        <>
            <div className={ classNames(styles.MorseButton, {[styles.wiggle]: !morseCodeSolved}) } data-wiggle={ nextSolutionLetterIndex }>
                <HeroButton fadeIn={ fadeInMorseButton } color={ HERO_BUTTON_COLOR_RED }
                            onMouseDown={ handleMouseDownEvent }
                            onMouseUp={ handleMouseUpEvent }
                            onTouchStart={ handleMouseDownEvent }
                            onTouchEnd={ handleMouseUpEvent }
                    />
            </div>

            <div className={ styles.Input }>{ lastLetterMorse }</div>
            { morseCodeSolved && <div className={ styles.RedBarWrapper }>
                <div className={ styles.RedBar } onAnimationEnd={ () => onSolved() }/>
            </div> }
        </>
    );
};

MorseButton.propTypes = {};

export default MorseButton;
