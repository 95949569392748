const IntroText = [

    {// 0
        text: "Das war doch gar nicht so schwer, oder?",
        duration: 2000,
    },
    {// 1
        text: "Bevor es weiter geht, sollte ich vielleicht noch ein paar Kleinigkeiten erklären.",
        duration: 3500,
    },
    {// 2
        text: "Das Wichtigste zuerst:",
        duration: 2000,
    },
    {// 3
        text: "Es gibt was zu gewinnen!",
        duration: 3000,
    },

    { // 4
        text: "Zuerst musst Du allerdings noch ein paar weitere Rätsel lösen.", duration: 3000,
    },
    { // 5
        text: "Sonst wär's ja langweilig...", duration: 2000 },
    {// 6
        text: "Hier siehst Du vier Schlösser, die jeweils mit Hilfe eines Zahlencodes geöffnet werden können.",
        duration: 4000,
    },

    { // 7
        text: "Um den Code einzugeben, brauchst du farbige Knöpfe.", duration: 2000 },

    { text: "So wie den hier.", duration: 3400 },
    { text: "Die anderen sind irgendwo auf dieser Seite versteckt.", duration: 4000 },

    { text: "Ein paar Tipps habe ich noch:", duration: 2000 },
    {
        text: "Falls Du mal irgendwo nicht weiterkommst oder Dir Hinweise fehlen...",
        duration: 2500,
    },
    { text: "...versuch erstmal ein anderes Rätsel zu lösen.", duration: 3000 },
    { text: "Zettel und Stift könntest Du vielleicht auch gebrauchen.", duration: 3000 },
    { text: "Und natürlich musst Du hier auch nicht alleine durch...", duration: 3000 },
    { text: "...Familie und Freunde (und notfalls auch das Internet) dürfen natürlich auch mithelfen.", duration: 4000 },
    { text: "Jetzt hab ich aber auch genug rumgelabert.", duration: 3000 },
    { text: "Auf geht's!", duration: 2000 },
    //

];

export default IntroText;
