import { AuthContext } from "contexts/AuthContext";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./LogoutButton.module.scss";

const LogoutButton = props => {
    const {signOut} = useContext(AuthContext)
    const handleLogout = async ()=>{
        await signOut();
    }

    return (
        <div className={styles.LogoutButton}>
            <button type="button" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
};

LogoutButton.propTypes = {};

export default LogoutButton;
