import classNames from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Lock.module.scss";

const Lock = ({
                  colors,
                  nextColorIndex,
                  showColorHint = false,
                  showNumberHint = false,
                  showNoteHint = false,
                  solved = false,
                  disabled = false,
                  fadeIn = false,
              }) => {

    const circles = useMemo(() => {
        return colors.map((color, index) => {
            let classList = classNames(styles.LockCircle, {
                [styles["showHint-" + color]]: showColorHint,
                [styles.showNumberHint]: showNumberHint,
                [styles.showNoteHint]: showNoteHint,
            });
            if (!disabled) {
                if (solved) {
                    classList += " " + styles[color] + " " + styles.solved;
                } else {
                    for (let i = 0; i < nextColorIndex; i++) {
                        if (i === index && i <= nextColorIndex) {
                            classList += " " + styles[color] + " " + styles.solved;
                        }
                    }
                }
            }

            return <div key={ index }
                        className={ classList }/>;
        });
    }, [colors, showColorHint, nextColorIndex, solved]);

    return (
        <div className={ classNames(styles.LockWrapper, { [styles.fadeIn]: fadeIn }) }>
            <div className={ classNames(styles.Lock, { [styles.disabled]: disabled, [styles.solved]: solved }) }>
                { circles }
            </div>
        </div>
    );
};

Lock.propTypes = {};

export default Lock;
