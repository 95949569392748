import HeroButton, { HERO_BUTTON_COLOR_BLUE, HERO_BUTTON_COLOR_GREEN } from "components/HeroButton/HeroButton.js";
import Content from "components/Layout/Content/Content.js";
import { FINAL_SCREEN_ROUTE } from "constants/appRoutes.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import NumberHints from "views/RainbowRiddle/NumberHints/NumberHints.js";
import RainbowList from "views/RainbowRiddle/RainbowList/RainbowList.js";
import styles from "./RainbowRiddle.module.scss";
import { ReactComponent as Note } from "./assets/Note.svg";

const initialOrder = ["yellow", "purple", "orange", "green", "blue"];
const rainbowOrder = ["red", "orange", "yellow", "green", "blue", "purple"];
const duckHintSolution = ["yellow", "green", "red", "blue", "orange", "purple"];
const RainbowRiddle = props => {
    const {
        redBarIsUnlocked,
        rainbowSolved,
        setRainbowSolved,
        duckHintSolved,
        setDuckHintSolved,
        showHints,
        numbersRiddleSolved,
        processLockButtonClick,
        allSolved,
        setShowHints
    } = useContext(UserProgressContext);
    const [heroButtonFadeIn, setHeroButtonFadeIn] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setShowHints(false)
    }, []);

    useEffect(() => {
        if(allSolved){
            history.replace(FINAL_SCREEN_ROUTE);
        }
    }, [allSolved, history]);

    const listItems = useMemo(() => {
        if (rainbowSolved) {
            return rainbowOrder;
        } else {
            const listItems = [...initialOrder];

            if (redBarIsUnlocked) {
                listItems.push("red");
            }
            return listItems;
        }
    }, [redBarIsUnlocked, rainbowSolved]);

    const correctOrder = useMemo(() => {
        if (rainbowSolved && numbersRiddleSolved) {
            return duckHintSolution;
        } else if (!rainbowSolved) {
            return rainbowOrder;
        }
        return [];

    }, [rainbowSolved]);

    const handleRiddleSolved = useCallback(() => {
        if (!rainbowSolved) {
            setHeroButtonFadeIn(true);
            setRainbowSolved(true);
        } else {
            setDuckHintSolved(true);
        }

    }, [rainbowSolved]);

    const rainbowList = useMemo(() => {
        return <RainbowList key={ new Date().getTime() } listItems={ listItems } onSolved={ handleRiddleSolved }
                            correctOrder={ correctOrder } redBarIsUnlocked={ redBarIsUnlocked }
                            rainbowSolved={ rainbowSolved } duckHintSolved={ duckHintSolved }
                            showHint={ showHints }/>;
    }, [listItems]);

    return (
        <Content>
            <div className={ styles.RainbowRiddle }>
                { !showHints ? <h1>Repariere die Regenbogenflagge</h1> :
                    <h1><s>Repariere die Regenbogenflagge</s> Finde die Noten</h1> }
                <div className={ styles.RainbowWrapper }>
                    { listItems.length > 0 && <div className={ styles.RiddleWrapper }>
                        { rainbowSolved && <NumberHints/> }
                        <div className={ styles.DndWrapper }>
                            { rainbowList }
                        </div>
                        { !redBarIsUnlocked &&
                        <>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div className={ styles.RedBarHint }/>
                        </> }
                    </div> }

                </div>
                { rainbowSolved && <HeroButton fadeIn={ heroButtonFadeIn } color={ HERO_BUTTON_COLOR_BLUE }
                                               onClick={ () => processLockButtonClick(HERO_BUTTON_COLOR_BLUE) }/> }


            </div>
        </Content>
    );
};

RainbowRiddle.propTypes = {};

export default RainbowRiddle;
