import React from 'react';
import classNames from 'classnames';
import styles from './FlexBoxWrapper.module.scss';

const FlexBoxWrapper = ({justifyContent='start', direction='row', children, style,...rest}) => {
    const classes = classNames(styles.FlexBoxWrapper,styles[`content-${justifyContent}`], styles[`direction-${direction}`]);

    return (
        <div className={classes} style={style}>
            {children}
        </div>
    );
};

FlexBoxWrapper.propTypes = {

};

export default FlexBoxWrapper;
