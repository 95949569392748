import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputPreElement.module.scss';

const InputPreElement = props => {
    return (
        <div className={styles.PreElement}>
            {props.text}
        </div>
    );
};

InputPreElement.propTypes = {
    text: PropTypes.string.isRequired
};

export default InputPreElement;
