// Home
export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/signup";
export const INTRO_ROUTE = "/intro";
export const WELCOME_ROUTE = "/welcome";
export const HUB_ROUTE = "/hub";
export const STORYBOOK_ROUTE = "/storybook";
export const NUMBERS_RIDDLE_ROUTE = "/numbers"
export const RAINBOW_RIDDLE_ROUTE = "/rainbow"
export const MORSE_RIDDLE_ROUTE = "/first"
export const SONG_RIDDLE_ROUTE = "/song"
export const DUCK_RIDDLE_ROUTE = "/duck"
export const FINAL_SCREEN_ROUTE = "/yeah"
export const START_OVER_SCREEN_ROUTE = "/startover"


// Auth


