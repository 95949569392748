import classNames from "classnames";
import Field from "components/Form/Field/Field.js";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./TextInput.module.scss";

const TextInput = React.forwardRef(({className, type = 'text', ...other}, ref) => {
    const classList = useMemo(()=>classNames(styles.TextInput, className), [className]);
    return (
        <input type={type} className={classList} {...other} ref={ref}/>
    );
});

TextInput.propTypes = {};

export default TextInput;
