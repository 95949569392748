import classNames from "classnames";
import { ReactComponent as NumbersLogoSvg } from "./assets/123.svg";
import React from "react";
import PropTypes from "prop-types";
import styles from "./NumbersLogo.module.scss";

const NumbersLogo = ({className}) => {
    return (
        <NumbersLogoSvg className={classNames(styles.NumbersLogo, className)} fill="currentColor" stroke="currentColor"/>
    );
};

NumbersLogo.propTypes = {};

export default NumbersLogo;
