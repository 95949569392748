import { getCsrfToken } from "api/loaders/authLoaders.js";
import classNames from "classnames";
import Button from "components/Button";
import Form from "components/Form/Form.js";
import Input from "components/Form/Input/Input.js";
import Box from "components/Layout/Box";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import SubmitButton from "components/SubmitButton/SubmitButton.js";
import TextInput from "components/TextInput/TextInput.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./LoginForm.module.scss";

// let defaultValues = { username: "paul", password: "test" };
const defaultValues = {};
const LoginForm = ({ className }) => {
	const { signIn } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [loginFailed, setLoginFailed] = useState(false);
	const { register, handleSubmit } = useForm({ defaultValues: defaultValues });

	const onSubmit = async (data, event) => {
		await getCsrfToken();

		try {
			setLoading(true);
			await signIn(data.username, data.password);
		} catch (error) {
			setLoginFailed(true);
			setLoading(false);
		}
	};

	return (
		<div className={classNames(styles.LoginFormWrapper, className)}>
			<Form dark={false} onSubmit={handleSubmit(onSubmit)}>
				<TextInput placeholder={"Benutzername"} {...register("username")} />
				<TextInput placeholder={"Passwort"} type={"password"} {...register("password")} />
				<FlexBoxWrapper justifyContent={"center"} direction={"column"} >
					{loginFailed && (
						<div className={styles.LoginFailed}>
							Login fehlgeschlagen
						</div>
					)}
					<SubmitButton type={"submit"} loading={loading} style={{ width: "8rem" }}>
						<div>Login</div>
					</SubmitButton>
				</FlexBoxWrapper>
			</Form>
		</div>
	);
};


export default LoginForm;
