import classNames from "classnames";
import DuckRainbow from "components/Duck/DuckRainbow.js";
import React from "react";
import {ReactComponent as DuckSvg} from "./assets/Duck.svg";
import PropTypes from "prop-types";
import styles from "./Duck.module.scss";

const Duck = ({className, withBackgroundImage=false}) => {

    if(withBackgroundImage){
        return <DuckRainbow className={classNames(styles.Duck, className)} stroke="currentColor"/>
    }

    return (
        <DuckSvg className={classNames(styles.Duck, className)} fill="currentColor" stroke="currentColor"/>
    );
};

Duck.propTypes = {};

export default Duck;
