import React from "react";
import PropTypes from "prop-types";
import MiniLock from "views/NumbersRiddle/MiniLock/MiniLock.js";
import NumberWrapper from "views/NumbersRiddle/NumbersForm/NumberWrapper/NumberWrapper.js";
import styles from "./NumbersSolved.module.scss";

const NumbersSolved = ({ showHints }) => {
    return (
        <div className={ styles.NumbersSolved }>
            { showHints && <div className={ styles.Hint }>(</div> }
            <NumberWrapper color="green">
                <div className={ styles.Number }>6</div>
            </NumberWrapper>
            { showHints && <div className={ styles.Hint }>+</div> }
            <NumberWrapper color="blue">
                <div className={ styles.Number }>9</div>
            </NumberWrapper>
            { showHints && <div className={ styles.Hint }>x</div> }
            <NumberWrapper color="orange">
                <div className={ styles.Number }>7</div>
            </NumberWrapper>
            { showHints && <div className={ styles.Hint }>x</div> }
            <NumberWrapper color="red">
                <div className={ styles.Number }>2</div>
            </NumberWrapper>
            { showHints && <div className={ styles.Hint }>)x</div> }
            <NumberWrapper color="purple">
                <div className={ styles.Number }>3</div>
            </NumberWrapper>
            { showHints && <div className={ styles.Hint }>=<MiniLock/></div> }
        </div>
    );
};

NumbersSolved.propTypes = {};

export default NumbersSolved;
