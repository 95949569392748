import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./AnimatedText.module.scss";

const AnimatedText = ({ textDefinitions, fadeOutLastText = true, onFinished, className, startIndex, endIndex }) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [endAfterIndex, setEndAfterIndex] = useState()

    useEffect(() => {
        setEndAfterIndex(endIndex || textDefinitions.length -1);
        setCurrentTextIndex(startIndex || 0);
    }, [startIndex, endIndex]);

    const handleAnimationEnd = () => {
        if (currentTextIndex === endAfterIndex) {
            if(onFinished){
                onFinished();
            }

        } else {
            setCurrentTextIndex(currentTextIndex + 1);
        }
    };

    let animationName = styles.fadeOut;
    const animationDuration = textDefinitions[currentTextIndex].duration + "ms";
    if(currentTextIndex === endAfterIndex && !fadeOutLastText){
        animationName = styles.stay
        className=styles.noFadeOut
    }

    return (
            <div className={ classNames(styles.AnimatedText, className) }>
                <div key={ currentTextIndex } onAnimationEnd={ handleAnimationEnd }
                     className={classNames({[styles.bold]:textDefinitions[currentTextIndex].bold})}
                     style={ { animationDuration: animationDuration, animationName:animationName,} }>{ textDefinitions[currentTextIndex].text } </div>
            </div>
    );
};

AnimatedText.propTypes = {};

export default AnimatedText;
