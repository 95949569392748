import { HUB_ROUTE } from "constants/appRoutes.js";
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styles from "./HomeButton.module.scss";

const HomeButton = props => {
    return (
        <div className={styles.HomeButton}>
            <NavLink to={HUB_ROUTE}>Zurück</NavLink>
        </div>
    );
};

HomeButton.propTypes = {};

export default HomeButton;
