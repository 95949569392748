import { HINT_RIDDLE_SOLUTION } from "constants/solutions.js";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import NumberWrapper from "views/NumbersRiddle/NumbersForm/NumberWrapper/NumberWrapper.js";
import styles from "./HintRiddle.module.scss";

const HintRiddle = ({onSolved}) => {
    const { register, handleSubmit, watch } = useForm({ mode: "onChange" });
    const fieldVales = watch();

    useEffect(() => {
        if(Object.values(fieldVales).join("") === HINT_RIDDLE_SOLUTION){
            onSolved();
        }
    }, [fieldVales]);

    return (
        <form className={ styles.HintRiddle } onSubmit={ handleSubmit }>
                <input type="text" maxLength="1" placeholder="?" { ...register("inputPurple") }/>
                <input type="text" maxLength="1" placeholder="?" { ...register("inputBlue") }/>
                <input type="text" maxLength="1" placeholder="?" { ...register("inputRed") }/>
        </form>
    );
};

HintRiddle.propTypes = {};

export default HintRiddle;
