import classNames from "classnames";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./RainbowColor.module.scss";
import { ReactComponent as NoteC } from "../../assets/Note_C.svg";
import { ReactComponent as Note } from "../../assets/Note.svg";

const RainbowColor = ({ dnd, item, showHint }) => {
    const { showHints } = useContext(UserProgressContext);
    const colorClassName = styles[item];

    if (dnd) {
        return (<li
            style={ { ...dnd.item.styles, ...dnd.handler.styles } }
            className={ classNames(styles.ListItemWrapper, dnd.item.classes, { [styles.showHint]: showHints }) }
            ref={ dnd.item.ref }
            { ...dnd.handler.listeners }
        >
            <div className={ classNames(styles[item], styles.RainbowColor) }>
                { colorClassName.search("purple") !== -1 ?
                    <NoteC className={ classNames(styles.Note, styles.NoteC) } fill="currentColor" stroke="currentColor"/> :
                    <Note className={ styles.Note } fill="currentColor" stroke="currentColor"/> }
            </div>
        </li>);
    }

    return (
        <li
            className={ classNames(styles.ListItemWrapper, { [styles.showHint]: showHints }) }
        >
            <div className={ classNames(styles[item], styles.RainbowColor) }>
                { colorClassName.search("purple") !== -1 ?
                    <NoteC className={ classNames(styles.Note, styles.NoteC) } fill="currentColor" stroke="currentColor"/> :
                    <Note className={ styles.Note } fill="currentColor" stroke="currentColor"/> }
            </div>
        </li>
    );
};

RainbowColor.propTypes = {};

export default RainbowColor;
