import Content from "components/Layout/Content/Content.js";
import React from "react";
import PropTypes from "prop-types";
import LoginForm from "views/Login/LoginForm/LoginForm.js";
import styles from "./Login.module.scss";

const Login = props => {
    return (
        <Content>
            <div className={styles.Login}>
                <LoginForm/>
            </div>
        </Content>
    );
};

Login.propTypes = {};

export default Login;
