import classNames from "classnames";
import HintRiddle from "components/HintRemote/HintRiddle/HintRiddle.js";
import RemoteButton from "components/HintRemote/RemoteButton/RemoteButton.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styles from "./HintRemote.module.scss";

const HintRemote = ({fadeOut}) => {
    const { hintRiddleSolved, setHintRiddleSolved } = useContext(UserProgressContext);
    const [showSwitchAnimation, setShowSwitchAnimation] = useState(false);

    const handleRiddleSolved = useCallback(() => {
        setShowSwitchAnimation(true);
    }, [setHintRiddleSolved]);

    const handleAnimationEnd = () => {
        setHintRiddleSolved(true);
    };

    const wrapperClasses = useMemo(() => {

        if (showSwitchAnimation && !hintRiddleSolved) {
            return classNames(styles.HintRemote, styles.switch);
        }
        return styles.HintRemote;

    }, [hintRiddleSolved,
        showSwitchAnimation]);
    return (
        <div className={ wrapperClasses } onAnimationEnd={ handleAnimationEnd }>
            { !hintRiddleSolved ? <HintRiddle onSolved={ handleRiddleSolved }/> :
                <RemoteButton fadeIn={ showSwitchAnimation } className={ styles.Remote } fadeOut={fadeOut}/> }
        </div>
    );
};

HintRemote.propTypes = {};

export default HintRemote;
