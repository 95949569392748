import { createUser, getCsrfToken } from "api/loaders/authLoaders.js";
import classNames from "classnames";
import Button from "components/Button";
import Form from "components/Form/Form.js";
import Input from "components/Form/Input/Input.js";
import Box from "components/Layout/Box";
import FlexBoxWrapper from "components/Layout/Wrappers/FlexBoxWrapper.js";
import SubmitButton from "components/SubmitButton/SubmitButton.js";
import TextInput from "components/TextInput/TextInput.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useMemo, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import styles from "./SignUpForm.module.scss";

// let defaultValues = { username: "paul", password: "12345678", passwordRepeat: "12345678" };
const defaultValues = {username: "", password: "", passwordRepeat: ""};

const SignUpForm = ({ className, invitationToken, onUserCreated }) => {
    const { signIn } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [userCreationFailed, setUserCreationFailed] = useState(false);
    const [userNameInUse, setUserNameInUse] = useState(false);
    const { register, handleSubmit, formState, watch, trigger } = useForm({ defaultValues: defaultValues });
    const formValues = watch();
    const formErrors = formState.errors;

    const onError = (errors) => {
        console.log(errors);
    };

    const onSubmit = async (data) => {
        await getCsrfToken();

        try {
            setLoading(true);
            const response = await createUser(data.username, data.password, invitationToken);
            onUserCreated();

        } catch (error) {
            if(error.response.data?.errors?.username) {
                setUserNameInUse(true);
            }
            else{
                setUserCreationFailed(true);
            }
            setLoading(false);
        }
    };

    const submitDisabled = useMemo(() => {
        return formValues.username.trim() === "" || formValues.password.trim() === "" || formValues.passwordRepeat.trim() === "";
    }, [formValues]);

    return (
        <div className={ classNames(styles.SignUpForm, className) }>
            <Form dark={ false } onSubmit={ handleSubmit(onSubmit, onError) }>
                <TextInput  { ...register("username", {
                    required: true,
                    pattern: {
                        value: /^[A-Za-z0-9]{4,100}$/g,
                        message: "Der Benutzername darf nur Buchstaben und Zahlen enthalten.",
                    },
                }) } placeholder={ "Benutzername" }/>
                <div className={ classNames(styles.InputHint, { [styles.Error]: formErrors.username }) }>
                    Mindestens 4 Zeichen, nur Buchstaben und Zahlen
                </div>
                <TextInput type={ "password" } { ...register("password", {
                    required: true,
                    minLength: {
                        value: 8,
                        message: "Das Passwort muss mindestens 8 Zeichen lang sein.",
                    },
                }) } placeholder={ "Passwort" }/>
                <div className={ classNames(styles.InputHint, { [styles.Error]: formErrors.password }) }>
                    Mindestens 8 Zeichen
                </div>
                <TextInput type={ "password" } { ...register("passwordRepeat", {
                    required: true,
                    validate: value => value === watch("password") || "Die Passwörter müssen übereinstimmen.",
                }) }
                           placeholder={ "Passwort wiederholen" }/>

                { formErrors.passwordRepeat && <div className={ classNames(styles.InputHint, styles.Error) }>
                    Die Passwörter müssen übereinstimmen
                </div> }

                <FlexBoxWrapper justifyContent={ "center" } direction={ "column" }>
                    { userCreationFailed && (
                        <div className={ classNames(styles.InputHint, styles.Error) }>
                            Benutzerdaten konnten nicht gespeichert werden.
                        </div>
                    ) }
                    {userNameInUse && <div className={ classNames(styles.InputHint, styles.Error) }>
                        Der Benutzername ist bereits vergeben.<br/>Bitte wähle einen anderen.
                    </div>}
                    <SubmitButton className={styles.FormSubmitButton} type={ "submit" } loading={ loading } style={ { width: "10rem" } }
                                  disabled={ submitDisabled }>
                        <div>Account anlegen</div>
                    </SubmitButton>
                </FlexBoxWrapper>
            </Form>
        </div>
    );
};


export default SignUpForm;
