import classNames from "classnames";
import styles from "components/Duck/Duck.module.scss";
import FlagImage from "assets/images/backgrounds/Gay_Pride_Flag.png";
import React from "react";

const DuckRainbow = ({className}) => {

    return <svg id="DuckShape" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 460">
        <defs>
            <pattern id="imgpattern" x="0" y="0" width="1" height="1">
                <image width="800" height="495"
                       xlinkHref={ FlagImage }/>
            </pattern>
        </defs>

        <path fill="url(#imgpattern)"
              d="M312.47,72c3.12-.29,6.22-.75,9.34-.82,5.92-.13,7.76,2.76,4.39,7.64-5.46,7.88-11.52,15.29-23.52,13.19,1.65,6.9,3.63,13.16,4.59,19.57,2.73,18.21,5.62,36.51-1.32,54.52A72.46,72.46,0,0,1,285.36,195c-10.14,8.77-21,16.67-31.6,25-1.23,1-2.48,1.92-5,3.85,3.63.17,5.85.36,8.06.36,10.67,0,21.35.27,32-.22,16-.73,30.69,4.53,45.34,9.71,18.2,6.45,36.27,13.26,54.33,20.08C400,258,410.6,256.53,420.72,250c15.86-10.32,31.77-20.59,44.68-34.69,4.83-5.26,9.35-10.81,13.94-16.28,3.75-4.47,7.29-4.23,9.93.94,2.94,5.73,4.48,11.69,1.31,17.71,3-.38,6.14-1.75,7.54-.73,1.94,1.41,3.12,4.51,3.67,7.08,3.32,15.77.87,30.92-5.25,45.66-9,21.62-17.55,43.41-26.85,64.89-12.55,29-30.23,54.53-54.27,75.14-19.06,16.34-41.69,26-65.86,32.17A300.76,300.76,0,0,1,287.79,451c-20.61.88-41.26,2.15-61.86,1.68-40-.93-79.19-6.9-115-26A209.41,209.41,0,0,1,77.5,404.36c-10.77-8.76-16.63-21.31-19.8-34.78-7.4-31.44-5.78-62.6,8-91.8,6.33-13.38,16.82-24.94,26.17-36.72,6.6-8.31,14.57-15.53,21.68-23.46,1.07-1.2,1.75-4.23,1-5.33-4-5.9-8.48-11.45-12.79-17.12A11.86,11.86,0,0,0,93,190.46c-7.72-.85-15.42-3.67-23-3.29-9.93.5-19.89,2.79-29.57,5.33-6.27,1.64-11,0-15.52-3.67A7.66,7.66,0,0,1,22.66,179a25.65,25.65,0,0,1,1.75-3c6-9.34,5.73-9.12-1-18.46-5-6.9-9.95-14.08-13.3-21.84-2.9-6.73-1.79-14.38,1-21.38,2.1-5.28,4.52-6.32,9.64-3.84s10,5.93,15.33,8.09c11.08,4.47,22.2,4.1,32.24-2.8,7.28-5,9.83-13.27,11.69-21.34,4-17.51,13.47-31.7,25.1-45,20.78-23.83,47.75-34.84,78.11-39.17,27.16-3.87,51.35,4.56,74,18.67,7.45,4.64,14.87,9.32,22.16,14.19,9.71,6.48,19.77,6.68,30.17,2.12,3.47-1.51,6.79-3.36,10.21-5,5.48-2.64,9.93-.12,8.8,5.82-.87,4.58-3.69,9-6.44,12.95-3,4.27-6.89,7.89-10.39,11.79Z"/>
    </svg>;

};

export default DuckRainbow;
