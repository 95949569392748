import FixedContent from "components/Layout/FixedContent/FixedContent.js";
import { HOME_ROUTE } from "constants/appRoutes.js";
import React from "react";
import styles from "./Layout.module.scss";
import {useLocation} from "react-router-dom";
const Layout = props => {
	const location = useLocation();
	return (
		<>
            <FixedContent/>
			{props.children}
		</>
	);
};

export default Layout;
