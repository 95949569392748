import classNames from "classnames";
import DndList from "./DndList/src/List.js";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RainbowColor from "views/RainbowRiddle/RainbowList/RainbowColor/RainbowColor.js";
import styles from "./RainbowList.module.scss";

const RainbowList = ({
                         listItems,
                         onSolved,
                         correctOrder,
                         duckHintSolved,
                         rainbowSolved,
                         showHint,
    redBarIsUnlocked
                     }) => {
    const [list, setList] = useState(listItems);

    useEffect(() => {
        if (correctOrder.join("") === list.join("")) {
            onSolved();
        }
    }, [list]);

    return (
        <ul className={ classNames(styles.RainbowList, {
            [styles.allSolved]: rainbowSolved && duckHintSolved,
        }) }>
            { (!rainbowSolved || !duckHintSolved) && <DndList
                items={ list }
                itemComponent={ RainbowColor }
                itemClassNames={ styles.RainbowColor}
                showHint={showHint}
                setList={ setList }
            /> }
            { (rainbowSolved && duckHintSolved) && correctOrder.map((color, index) => {
                return <RainbowColor key={ index } item={ color } itemClassNames={ styles.RainbowColor }/>;
            }) }
        </ul>
    );
};

RainbowList.propTypes = {};

export default RainbowList;
