import classNames from "classnames";
import { SIGNUP_ROUTE } from "constants/appRoutes.js";
import { APP_URL } from "constants/env.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styles from "./InviteButton.module.scss";
import useClipboard from "react-use-clipboard";

const InviteButton = ({}) => {
    const { user } = useContext(AuthContext);
    const [isCopied, setCopied] = useClipboard(`${ APP_URL }app${ SIGNUP_ROUTE }?token=${ user.invitation_token }`, {successDuration: 1000});
    const handleInviteButtonClick = () => {
        setCopied();
    };

    return (
        ReactDOM.createPortal(
            <div className={ classNames(styles.InviteButton, {[styles.copied]:isCopied}) } onClick={ handleInviteButtonClick }>
                Einladungslink { isCopied ? "kopiert":"kopieren" }
            </div>, document.getElementById("root"),
        )
    );
};

InviteButton.propTypes = {};

export default InviteButton;
