import HeroButton, { HERO_BUTTON_COLOR_RED } from "components/HeroButton/HeroButton.js";
import Content from "components/Layout/Content/Content.js";
import { FINAL_SCREEN_ROUTE } from "constants/appRoutes.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import MorseButton from "views/SongRiddle/MorseButton/MorseButton.js";
import SongForm from "views/SongRiddle/SongForm/SongForm.js";
import SongText from "views/SongRiddle/SongText/SongText.js";
import styles from "./SongRiddle.module.scss";

const SongRiddle = props => {
    const {
        songRiddleSolved,
        setSongRiddleSolved,
        redBarIsUnlocked,
        setRedBarIsUnlocked,
        processLockButtonClick,
        allSolved,
        setShowHints
    } = useContext(UserProgressContext);
    const [fadeInMorseButton, setFadeInMorseButton] = useState(false)
    const history = useHistory();

    useEffect(() => {
        setShowHints(false)
    }, []);

    useEffect(() => {
        if(allSolved){
            history.replace(FINAL_SCREEN_ROUTE);
        }
    }, [allSolved, history]);

    const handleSolved = async () => {
        setFadeInMorseButton(true);
        setSongRiddleSolved(true);
    };

    const handleMorseSolved = () => {
        setRedBarIsUnlocked(true);
    };

    return (
        <Content>
            <div className={ styles.SongRiddle }>
                <h1>Welche Band spielt dieses Lied?</h1>
                <SongText/>
                { !songRiddleSolved ? <SongForm onSolved={ handleSolved }/> : redBarIsUnlocked ?
                    <HeroButton color={ HERO_BUTTON_COLOR_RED } onClick={()=>processLockButtonClick(HERO_BUTTON_COLOR_RED)}/> : <MorseButton onSolved={handleMorseSolved} fadeInMorseButton={fadeInMorseButton}/> }
            </div>
        </Content>
    );
};

SongRiddle.propTypes = {};

export default SongRiddle;
