import axios from "axios";
import { HOME_ROUTE } from "constants/appRoutes.js";
import {API_BASE_URL} from "constants/env.js"

const axiosApi = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true
});

axiosApi.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        // return Error object with Promise
        return Promise.reject(error);
    },
);

export default axiosApi;
