import { EMPTY_INPUT_VALUE_PLACEHOLDER } from "../constants/placeholderConstants";

export const getViewModeValue = (value, type = null, selectOptions = null) => {
	if (!type) {
		return value || EMPTY_INPUT_VALUE_PLACEHOLDER;
	}

	if (type === "select") {
		value = selectOptions.find(selectOption => selectOption.value === value);
		if (value) {
			return value.displayValue;
		}
		return EMPTY_INPUT_VALUE_PLACEHOLDER;
	}

	if (type === "textarea") {
		if (value) {
			return value;
		}
		return EMPTY_INPUT_VALUE_PLACEHOLDER;
	}
};
