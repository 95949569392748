import classNames from "classnames";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./SongText.module.scss";

const SongText = props => {
    const { showHints } = useContext(UserProgressContext);
    return (
        <div className={ classNames(styles.SongText, { [styles.showHints]: showHints }) }>
            <div className={styles.Background}>
                <div/>
                <div/>
                <div/>
            </div>
            <div className={ styles.SongTextText }>
                <div>
                    <p>Haut, die flimmert<br/>Sie ist so ein kleiner DJ</p>
                    <p>Komm schnell hin<br/>Auf der Straße, <br/>aber nicht auf dem Freiweg</p>
                    <p>Dreh den Trick um<br/>Um ein bisschen Spielraum zu haben</p>
                </div>
                <div>
                    <p>Schlag das schön
                        <br/>
                        Aber nicht die Art, wie wir spielen
                    </p>
                    <p>Blutbad in der Hundestadt
                        <br/>
                        Weicher Schwanz des Brustkorbs
                    </p>
                    <p/>
                </div>
                <div>
                    <p>In einer Reihe stehen<br/>
                        Um die Show heute Abend zu sehen</p>
                    <p>Und es brennt ein Licht<br/>
                        Schweres Leuchten</p>
                    <p>Übrigens habe ich versucht zu sagen<br/>
                        Ich wäre da und warte auf...</p>
                </div>
            </div>
        </div>
    );
};

SongText.propTypes =
    {}
;

export default SongText;
