import React from "react";
import PropTypes from "prop-types";

const NumberHints = props => {
    return (
        <>
            <p>Addiert man die grüne und die violette Zahl, ergibt es die blaue Zahl.</p>
            <p>Teilt man eine der Zahlen durch eine der anderen Zahlen ergibt es die rote Zahl.</p>
            <p>Es gibt zwei gerade und drei ungerade Zahlen.</p>
            <p>Weder die höchste noch die tiefste Zahl sind an den beiden Enden.</p>
            <p>Keine Zahl kommt doppelt vor.</p>
            <p>Addiert man 4 zur violetten Zahl erhält man die orange Zahl.</p>
        </>
    );
};

NumberHints.propTypes = {};

export default NumberHints;
