import classNames from "classnames";
import React from 'react';
import styles from './Content.module.scss';

const Content = ({children,  className}) => {
    return <main className={classNames(styles.Content, className)}>
        {children}
    </main>
};

export default Content;
