import { validateInvitationtoken } from "api/loaders/authLoaders.js";
import AnimatedText from "components/AnimatedText/AnimatedText.js";
import HeroButton, { HERO_BUTTON_COLOR_RED, HERO_BUTTON_SIZE_SMALL } from "components/HeroButton/HeroButton.js";
import { LOGIN_ROUTE } from "constants/appRoutes.js";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import SignUpForm from "views/SignUp/SignUpForm/SignUpForm.js";
import SignUpText from "views/SignUp/SignUpText.js";
import styles from "./SignUp.module.scss";
import queryString from "query-string";

const SignUp = props => {
    const location = useLocation();
    const history = useHistory();
    const [checkingToken, setCheckingToken] = useState(true);
    const [validInvitationToken, setValidInvitationToken] = useState(null);
    const [userCreated, setUserCreated] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showLoginLink, setShowLoginLink] = useState(false);

    useAsyncEffect(async () => {
        const token = queryString.parse(location.search).token;

        if (token) {
            try {
                await validateInvitationtoken(token);
                setValidInvitationToken(token);
            } catch (error) {
                setValidInvitationToken(null);
            }
        }
        setCheckingToken(false);
    }, []);

    const handleIntroFinished = () => {
        setShowForm(true);
    };

    const handleUserCreated = () => {
        setUserCreated(true);
        setShowForm(false)
    };

    return (
        <div className={ styles.SignUp }>
            { !checkingToken && validInvitationToken && !userCreated && !showForm &&
            <div className={ styles.WelcomeMessage }>
                <AnimatedText textDefinitions={ SignUpText } onFinished={ handleIntroFinished } endIndex={2}/>
            </div>
            }
            { showForm && <SignUpForm invitationToken={ validInvitationToken } onUserCreated={ handleUserCreated }/>
            }
            { !checkingToken && !validInvitationToken && !userCreated &&
            <p>Dieser Einladungslied funktioniert leider nicht :/</p>
            }
            { userCreated &&
            <div className={ styles.WelcomeMessage }>
                <AnimatedText textDefinitions={ SignUpText } fadeOutLastText={ false } onFinished={ () => {
                    setShowLoginLink(true);
                } } startIndex={ 3 }/>
            </div>
            }
            { showLoginLink && <HeroButton size={HERO_BUTTON_SIZE_SMALL} color={HERO_BUTTON_COLOR_RED} fadeIn={true} onClick={()=>{history.push(LOGIN_ROUTE)}}/> }

        </div>
    );
}
;

SignUp.propTypes =
{
}
;

export default SignUp;
