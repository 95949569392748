import HeroButton, {
    HERO_BUTTON_COLOR_BLACK,
    HERO_BUTTON_SIZE_SMALL,
} from "components/HeroButton/HeroButton.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styles from "./RemoteButton.module.scss";

const RemoteButton = ({ className, fadeIn, fadeOut }) => {
    const { setShowHints, processLockButtonClick, currentLockIndex } = useContext(UserProgressContext);

    const handleMouseDown = (event) => {
        event.preventDefault();
        if (currentLockIndex === 3) {
            processLockButtonClick(HERO_BUTTON_COLOR_BLACK);
        }
        setShowHints(true);
    };

    const handleMouseUp = (event) => {
        event.preventDefault();
        setShowHints(false);
    };

    return (
        <div className={ className }>
            <HeroButton size={ HERO_BUTTON_SIZE_SMALL } color={ HERO_BUTTON_COLOR_BLACK } fadeIn={ fadeIn }
                        fadeOut={ fadeOut }

                        onTouchStart={ handleMouseDown }
                        onTouchEnd={ handleMouseUp }
                        onMouseDown={ handleMouseDown }
                        onMouseUp={ handleMouseUp }
            />
        </div>
    );
};

RemoteButton.propTypes = {};

export default RemoteButton;
