import classNames from "classnames";
import AnimatedText from "components/AnimatedText/AnimatedText.js";
import BandIcon from "components/BandIcon/BandIcon.js";
import Duck from "components/Duck/Duck.js";
import HeroButton, {
    HERO_BUTTON_COLOR_GREEN,
} from "components/HeroButton/HeroButton.js";
import NumbersLogo from "components/NumbersLogo/NumbersLogo.js";
import {
    DUCK_RIDDLE_ROUTE, HUB_ROUTE,
    NUMBERS_RIDDLE_ROUTE,
    RAINBOW_RIDDLE_ROUTE,
    SONG_RIDDLE_ROUTE, START_OVER_SCREEN_ROUTE,
} from "constants/appRoutes.js";
import { LOCK_FOUR_SOLUTION, LOCK_ONE_SOLUTION, LOCK_THREE_SOLUTION, LOCK_TWO_SOLUTION } from "constants/solutions.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import InviteButton from "views/InviteButton/InviteButton.js";
import FinalScreenText from "./FinalScreenText.js";
import Lock from "./Lock/Lock.js";
import styles from "../Hub/Hub.module.scss";

const FinalScreen = props => {
    const history = useHistory();
    const { setWasSolvedBefore, wasSolvedBefore, allSolved } = useContext(UserProgressContext);
    const [showAnimation, setShowAnimation] = useState(false);
    const [textIndexStart, setTextIndexStart] = useState(0);
    const [textIndexEnd, setTextIndexEnd] = useState(5);

    useEffect(() => {
        if(!allSolved){
            history.push(HUB_ROUTE);
        }
    }, [history, allSolved]);

    const handleTextAnimationFinished = () => {
        if (!wasSolvedBefore) {
            setWasSolvedBefore(true);
        }

        if(textIndexStart === 0){
            setTextIndexStart(6);
            setTextIndexEnd(null);
        }
    };

    return (
        <div className={ classNames(styles.Hub, styles.FinalScreen, { [styles.animate]: showAnimation }) }>
            <div className={ styles.FlexWrapper }>
                <div className={ styles.IconWrapper }>
                    <NavLink to={ RAINBOW_RIDDLE_ROUTE }>
                        <div className={ styles.FlagIcon }/>
                    </NavLink>
                </div>

                <div className={ classNames(styles.IconWrapper, styles.DuckIconWrapper) }>
                    <NavLink to={ DUCK_RIDDLE_ROUTE }><Duck className={ styles.DuckIcon }/></NavLink>
                </div>

                <HeroButton className={ styles.HeroButton }
                            color={ HERO_BUTTON_COLOR_GREEN }
                            pulse={ setShowAnimation }
                            onClick={ () => history.push(START_OVER_SCREEN_ROUTE) }
                />

                <div className={ styles.IconWrapper }>
                    <NavLink to={ NUMBERS_RIDDLE_ROUTE }><NumbersLogo className={ styles.NumberIcon }/></NavLink>
                </div>

                <div className={ styles.IconWrapper }>
                    <NavLink to={ SONG_RIDDLE_ROUTE }><BandIcon className={ styles.BandIcon }/></NavLink>
                </div>
            </div>
            <div className={ styles.FinalTextWrapper } onAnimationEnd={ () => {
                setShowAnimation(true);
            } }>
                <AnimatedText textDefinitions={ FinalScreenText } fadeOutLastText={ textIndexStart < 6 }
                              onFinished={ handleTextAnimationFinished } startIndex={ textIndexStart }
                              endIndex={ textIndexEnd }/>
            </div>
            <div className={ styles.FlexWrapper }>
                <Lock colors={ LOCK_ONE_SOLUTION } nextColorIndex={ 0 }
                      solved={ true } animate={ showAnimation }/>
                <Lock colors={ LOCK_TWO_SOLUTION } nextColorIndex={ 0 }
                      solved={ true } animate={ showAnimation }/>
                <Lock colors={ LOCK_THREE_SOLUTION } nextColorIndex={ 0 } solved={ true }
                      animate={ showAnimation }/>
                <Lock colors={ LOCK_FOUR_SOLUTION } nextColorIndex={ 0 } solved={ true }
                      animate={ showAnimation }/>
            </div>
        </div>
    );
};

FinalScreen.propTypes = {};

export default FinalScreen;
