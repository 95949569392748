
import HeroButton, { HERO_BUTTON_COLOR_PURPLE } from "components/HeroButton/HeroButton.js";
import Content from "components/Layout/Content/Content.js";
import { FINAL_SCREEN_ROUTE } from "constants/appRoutes.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NumbersForm from "views/NumbersRiddle/NumbersForm/NumbersForm.js";
import NumbersSolved from "views/NumbersRiddle/NumbersSolved/NumbersSolved.js";
import styles from "./NumbersRiddle.module.scss";

const NumbersRiddle = props => {
    const { numbersRiddleSolved, setNumbersRiddleSolved, showHints, processLockButtonClick, allSolved, setShowHints } = useContext(UserProgressContext);
    const [heroButtonFadeIn, setHeroButtonFadeIn] = useState(false)
    const history = useHistory();

    useEffect(() => {
        setShowHints(false)
    }, []);

    useEffect(() => {
        if(allSolved){
            history.replace(FINAL_SCREEN_ROUTE);
        }
    }, [allSolved, history]);
    const handleRiddleSolved = () => {
        setHeroButtonFadeIn(true)
        setNumbersRiddleSolved(true);
    };
    return (
        <Content>
            <div className={ styles.NumbersRiddle }>
                <h1 className={ styles.Header }>
                    Finde die Zahlen
                </h1>
                <div className={ styles.Explanation }>
                    <p>Gesucht sind 5 Zahlen: Jede Farbe entspricht einer Zahl zwischen 1 und 9.</p>
                    <p>Die grüne Zahl ist eine 6.</p>
                </div>
                { !numbersRiddleSolved ? <NumbersForm onSolved={ handleRiddleSolved }/> :
                    <NumbersSolved showHints={ showHints }/> }
                {numbersRiddleSolved && <HeroButton fadeIn={heroButtonFadeIn} color={HERO_BUTTON_COLOR_PURPLE} onClick={ () => processLockButtonClick(HERO_BUTTON_COLOR_PURPLE) }/>}
            </div>
        </Content>
    );
};

NumbersRiddle.propTypes = {};

export default NumbersRiddle;
