import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader.js";
import styles from "./Button.module.scss";

export const ButtonStyles = {
	Default: "default",
	Error: "error",
};

const createButtonVariant = ({ className, color='primary', size='m', buttonStyle, disabled, type, children, to, loading, onClick, icon, ...rest }) => {
	const SvgIcon = icon || null;
	let Elem = "button";
	if (to) {
		Elem = Link;
	}
	const props = {};
	if (onClick) {
		props.onClick = onClick;
	} else if (to) {
		props.to = to;
	}

	if(loading){
	    children = <PulseLoader size={'6px'} color={'#fff'}/>
    }

	return (
		<Elem
			className={classNames(
				styles.btn,
                styles[color],
                styles[`btn-size-${size}`],
				{ [styles.disabled]: disabled },
				className,
			)}
			disabled={disabled}
			type={type}
			{...props}
			{...rest}
		>
			{icon && <SvgIcon className={styles.icon} />}
			{typeof children === "string" ? <div className={styles.label}>{children}</div> : children}
		</Elem>
	);
};

const ButtonContext = React.createContext();

const Solid = ({ className, children, icon, ...rest }) =>
	createButtonVariant({children, icon, ...rest});

// const Secondary = ({ className, children, icon, ...rest }) =>
// 	createButtonVariant({ className: classNames(styles.secondary, className), children, icon, ...rest });
//
// const Simple = ({ className, children, icon, ...rest }) =>
// 	createButtonVariant({ className: classNames(styles.simple, className), children, icon, ...rest });

const IconButton = ({ className, children, icon, ...rest }) => {
	const SvgIcon = icon;
	return createButtonVariant({ className: classNames(styles.iconButton, className), children: <SvgIcon />, ...rest });
};

const Button = ({ children }) => <ButtonContext.Provider value={""}>{children}</ButtonContext.Provider>;

Button.Solid = Solid;
// Button.Secondary = Secondary;
// Button.Simple = Simple;
Button.Icon = IconButton;

Button.propTypes = {
	className: PropTypes.string,
	buttonStyle: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	to: PropTypes.string,
	onClick: PropTypes.func,
	// @TODO: add icon here
};
Button.defaultProps = {
	buttonStyle: ButtonStyles.Default,
	disabled: false,
	type: "submit",
	to: null,
	onClick: null,
};

export default Button;
