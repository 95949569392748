const SignUpText = [
    {
        text: "Ein weiser Mensch sagte mal: \"Wirklich erwachsen ist nur, wer ordentlich rätseln kann\".",
        duration: 4000,

    }, {
        text: "Ich sage, wirklich erwachsen ist, wer sich auf dubiosen Websiten einen Account erstellt, ohne zu wissen, was das eigentlich soll.",
        duration: 6000,
    },
    {
        text: "Na, dann...",
        duration: 2000,
    },
    {
        text: "Sehr schön, das hat schon mal geklappt. Dann kann's ja losgehen.",
        duration: 2000,
    },
    {
        text: "Schnapp' Dir am besten einen Laptop, Tablet oder irgendwas mit einem größeren Bildschirm, denn nur dort funktioniert die Seite richtig.",
        duration: 5000,
    },
    {
        text: "Bis gleich!",
        duration: 100,
    },


];

export default SignUpText;
