import AppRouter from "AppRouter.js";
import { AuthContextProvider } from "contexts/AuthContext";
import { UserProgressContextProvider } from "contexts/UserProgressContext";
import React from "react";
import axiosApi from "api/axiosApi.js";
import { getAccessToken } from "services/tokenService.js";

const App = () => {
    // const accessToken = getAccessToken();
    // axiosApi.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

    return (
        <AuthContextProvider>
                <AppRouter/>
        </AuthContextProvider>
    );
};

export default App;
