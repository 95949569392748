import classNames from "classnames";
import HeroButton, { HERO_BUTTON_COLOR_GREEN, HERO_BUTTON_SIZE_LARGE } from "components/HeroButton/HeroButton.js";
import Content from "components/Layout/Content/Content.js";
import { HUB_ROUTE, INTRO_ROUTE } from "constants/appRoutes.js";
import { AuthContext } from "contexts/AuthContext";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { alphabet, longPressThreshold, newWordThreshold } from "utils/morseUtils.js";
import styles from "./MorseRiddle.module.scss";

const MorseRiddle = props => {
        const { user } = useContext(AuthContext);

        const history = useHistory();
        const { morseSolved, setMorseSolved } = useContext(UserProgressContext);
        const timeLastMouseUp = useRef(null);
        const timeLastMouseDown = useRef(null);
        const newWordTimeOut = useRef(null);
        const currentLetter = useRef("");
        const [lastLetterMorse, setLastLetterMorse] = useState("");
        const [nextSolutionLetterIndex, setNextSolutionLetterIndex] = useState(0);
        const [showBigButton, setShowBigButton] = useState(false);
        const [solution, setSolution] = useState(null);
        const [riddleSolved, setRiddleSolved] = useState(false)

        const correctLetters = user.username.substring(0, nextSolutionLetterIndex);

        useEffect(() => {
            setSolution(user.username.split(""));
            // setSolution(["e","e"])
        }, []);

        useEffect(() => {
            if (morseSolved) {
                history.replace(HUB_ROUTE);
            }
        }, [morseSolved]);

        const handleMouseDownEvent = (event) => {
            event.preventDefault();
            timeLastMouseDown.current = new Date().getTime();
            clearTimeout(newWordTimeOut.current);
        };

        const handleMouseUpEvent = (event) => {
            event.preventDefault();
            const mouseUpTime = new Date().getTime();
            let detectedMorseInput = "·";
            if (mouseUpTime - timeLastMouseDown.current > longPressThreshold) {
                detectedMorseInput = "-";
            }

            currentLetter.current += detectedMorseInput;
            setLastLetterMorse(currentLetter.current);
            timeLastMouseUp.current = mouseUpTime;
            newWordTimeOut.current = setTimeout(() => {
                const detectedLetter = alphabet[currentLetter.current];

                let solved = false;
                if (detectedLetter && solution[nextSolutionLetterIndex] && solution[nextSolutionLetterIndex].toLowerCase() === detectedLetter) {
                    if (nextSolutionLetterIndex + 1 === solution.length) {
                        solved = true;
                        setLastLetterMorse("");
                    }
                    setNextSolutionLetterIndex(prevState => prevState + 1);
                } else {
                    setNextSolutionLetterIndex(0);
                }
                if (solved) {
                    setRiddleSolved(true);
                } else {
                    currentLetter.current = "";
                    setLastLetterMorse("");
                }
            }, newWordThreshold);
        };

        const handleAnimationEnd = (event)=>{
            switch(event.animationName){
                case styles.bigButtonWrapperAppear:
                    setShowBigButton(true);
                    break;
                case styles.fadeOut:
                    setMorseSolved(true);
                    history.replace(INTRO_ROUTE)
                    break;
            }
        }

        return (
            <Content>
                { !morseSolved && <div className={ classNames(styles.MorseRiddle, {[styles.riddleSolved]:riddleSolved})} onAnimationEnd={handleAnimationEnd}>
                    <div className={ styles.Headline }>Wie war noch gleich Dein Benutzername?</div>
                    <div className={ styles.BigButtonWrapper } onAnimationEnd={ handleAnimationEnd }>
                        { showBigButton && <><HeroButton wrapperClassName={ styles.BigButton }
                                                         size={ HERO_BUTTON_SIZE_LARGE }
                                                         color={ HERO_BUTTON_COLOR_GREEN }
                                                         onMouseDown={ handleMouseDownEvent }
                                                         onMouseUp={ handleMouseUpEvent }
                                                         onTouchStart={handleMouseDownEvent}
                                                         onTouchEnd={handleMouseUpEvent}


                                                         fadeIn={ true }/>
                            <div className={ styles.Input }>{ correctLetters +" " +lastLetterMorse }</div>
                        </> }
                    </div>
                </div> }
            </Content>
        );
    }
;

MorseRiddle.propTypes =
    {}
;

export default MorseRiddle;
