const FinalScreenText = [
    {
        text: "",
        duration: 1000,
    },
    {
        text: "Yeah!",
        duration: 2000,
        bold:true
    },
    {
        text: "Du hast es geschafft!",
        duration: 3000,
        bold:true
    },
    {
        text: "Ich hoffe, es hat Spaß gemacht.",
        duration: 3000,
    },
    {
        text: "Für den versprochenen Preis, wende Dich bitte an den Entwickler Deines Vertrauens ;)",
        duration: 4000,
    },
    {
        text: "Falls Du noch mal von Vorne beginnen willst, drücke den großen, grünen Knopf.",
        duration: 3000,
    },
    {
        text: "Und falls Du das Rätsel noch anderen Leuten zeigen möchtest, kannst Du das mit diesem Button machen.",
        duration: 5000,
    },
    {
        text: "Aber jetzt lass Dich erstmal ordentlich feiern!",
        duration: 2000,
    },

];

export default FinalScreenText;
