import Duck from "components/Duck/Duck.js";
import HeroButton, {
    HERO_BUTTON_COLOR_BLUE,
    HERO_BUTTON_COLOR_GREEN,
    HERO_BUTTON_COLOR_ORANGE, HERO_BUTTON_COLOR_PURPLE,
    HERO_BUTTON_COLOR_RED, HERO_BUTTON_SIZE_MEDIUM, HERO_BUTTON_SIZE_SMALL,
} from "components/HeroButton/HeroButton.js";
import Content from "components/Layout/Content/Content.js";
import { FINAL_SCREEN_ROUTE } from "constants/appRoutes.js";
import { DUCK_RIDDLE_SOLUTION } from "constants/solutions.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styles from "./DuckRiddle.module.scss";


/*
    a = yellow //
    g = green // entchen
    f = red // ne
    e = blue // mei
    d =  orange // le
    c =  purple // al

    Sequenz = g g e f c d // green green blue red purple orange
*/
const DuckRiddle = props => {
    const {
        showHints,
        duckRiddleSolved,
        setDuckRiddleSolved,
        processLockButtonClick,
        allSolved,
        setShowHints
    } = useContext(UserProgressContext);
    const [nextSolutionColorIndex, setNextSolutionColorIndex] = useState(0);
    const [growHeroButton, setGrowHeroButton] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setShowHints(false)
    }, []);

    useEffect(() => {
        if(allSolved){
            history.replace(FINAL_SCREEN_ROUTE);
        }
    }, [allSolved ]);

    const onButtonClick = (color) => {
        if (color === DUCK_RIDDLE_SOLUTION[nextSolutionColorIndex]) {
            if (nextSolutionColorIndex + 1 === DUCK_RIDDLE_SOLUTION.length) {
                setGrowHeroButton(true);
            } else {
                setNextSolutionColorIndex(prevState => prevState + 1);
            }
        } else {
            setNextSolutionColorIndex(0);
        }
    };

    const onHeroButtonAnimationEnd = () => {
        setDuckRiddleSolved(true);
    };

    return (
        <Content>
            <div className={ styles.DuckRiddle }>
                <div className={ styles.Header }>
                    <Duck className={ styles.Duck } withBackgroundImage={ showHints }/>
                    <div className={ styles.HeaderText }>mei-ne al-le{showHints && <span> (in C-Dur)</span>}</div>

                </div>
                <div className={ styles.ButtonWrapper }>
                    <HeroButton size={ HERO_BUTTON_SIZE_SMALL } color={ HERO_BUTTON_COLOR_RED }
                                onClick={ () => onButtonClick("red") }
                                disabled={ duckRiddleSolved }/>

                    { !duckRiddleSolved ? <HeroButton size={ HERO_BUTTON_SIZE_SMALL }
                                                      onClick={ () => onButtonClick("orange") }
                                                      color={ HERO_BUTTON_COLOR_ORANGE }
                                                      grow={ growHeroButton }
                                                      onAnimationEnd={ onHeroButtonAnimationEnd }
                        /> :
                        <HeroButton size={ HERO_BUTTON_SIZE_MEDIUM }
                                    onClick={ () => processLockButtonClick(HERO_BUTTON_COLOR_ORANGE) }
                                    color={ HERO_BUTTON_COLOR_ORANGE }/> }

                    <HeroButton size={ HERO_BUTTON_SIZE_SMALL } color={ HERO_BUTTON_COLOR_GREEN }
                                onClick={ () => onButtonClick("green") }
                                disabled={ duckRiddleSolved }/>

                    <HeroButton size={ HERO_BUTTON_SIZE_SMALL } color={ HERO_BUTTON_COLOR_BLUE }
                                onClick={ () => onButtonClick("blue") }
                                disabled={ duckRiddleSolved }/>
                    <HeroButton size={ HERO_BUTTON_SIZE_SMALL } color={ HERO_BUTTON_COLOR_PURPLE }
                                onClick={ () => onButtonClick("purple") }
                                disabled={ duckRiddleSolved }/>
                </div>
            </div>

        </Content>
    );
};

DuckRiddle.propTypes = {};

export default DuckRiddle;
