import classNames from "classnames";
import BandIcon from "components/BandIcon/BandIcon.js";
import Duck from "components/Duck/Duck.js";
import HeroButton, {
    HERO_BUTTON_COLOR_GREEN,
} from "components/HeroButton/HeroButton.js";
import NumbersLogo from "components/NumbersLogo/NumbersLogo.js";
import {
    DUCK_RIDDLE_ROUTE, FINAL_SCREEN_ROUTE, MORSE_RIDDLE_ROUTE,
    NUMBERS_RIDDLE_ROUTE,
    RAINBOW_RIDDLE_ROUTE,
    SONG_RIDDLE_ROUTE,
} from "constants/appRoutes.js";
import { LOCK_FOUR_SOLUTION, LOCK_ONE_SOLUTION, LOCK_THREE_SOLUTION, LOCK_TWO_SOLUTION } from "constants/solutions.js";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import Lock from "views/Hub/Lock/Lock.js";
import styles from "./Hub.module.scss";

const Hub = props => {
    const {
        processLockButtonClick, currentLockIndex,
        currentLockNextColorIndex,
        allSolved,
        showHints,
        introWatched,
        morseSolved,
        setShowHints,
    } = useContext(UserProgressContext);

    const history = useHistory();

    useEffect(() => {
        if (allSolved) {
            history.replace(FINAL_SCREEN_ROUTE);
        }
        else if (!introWatched || !morseSolved) {
            history.replace(MORSE_RIDDLE_ROUTE);
        }
    }, [allSolved, history, introWatched,
        morseSolved]);

    useEffect(() => {
        setShowHints(false)
    }, []);

    const locks = useMemo(() => {
        return [LOCK_ONE_SOLUTION,
            LOCK_TWO_SOLUTION,
            LOCK_THREE_SOLUTION,
            LOCK_FOUR_SOLUTION,
        ].map((colors, index) => {
            const disabled = currentLockIndex < index;
            let solved = currentLockIndex > index;
            const active = currentLockIndex === index;

            return <Lock key={ index + currentLockIndex } colors={ colors } nextColorIndex={ currentLockNextColorIndex }
                         showColorHint={ index === 0 }
                         showNoteHint={ index === 2 && active }
                         showNumberHint={ index === 1 && active }
                         solved={ solved } disabled={ disabled }/>;
        });
    }, [currentLockIndex, currentLockNextColorIndex]);

    return (
        <div className={ styles.Hub }>
            <div className={ styles.FlexWrapper }>
                <div className={ styles.IconWrapper }>
                    <NavLink to={ RAINBOW_RIDDLE_ROUTE }>
                        <div className={ styles.FlagIcon }/>
                    </NavLink>
                </div>
                <div className={ styles.IconWrapper }>
                    <NavLink to={ DUCK_RIDDLE_ROUTE }><Duck className={ styles.DuckIcon }/></NavLink>
                </div>
                <HeroButton className={ styles.HeroButton }
                            color={ HERO_BUTTON_COLOR_GREEN }
                            onClick={ () => processLockButtonClick(HERO_BUTTON_COLOR_GREEN) }
                />
                <div className={ styles.IconWrapper }>
                    <NavLink to={ NUMBERS_RIDDLE_ROUTE }><NumbersLogo className={ styles.NumberIcon }/></NavLink>
                </div>
                <div className={ styles.IconWrapper }>
                    <NavLink to={ SONG_RIDDLE_ROUTE }><BandIcon
                        className={ classNames(styles.BandIcon, { [styles.HintAnimation]: currentLockIndex === 3 && showHints }) }/></NavLink>
                </div>
            </div>
            <div className={ styles.FlexWrapper }>
                { locks }
            </div>

        </div>
    );
};

Hub.propTypes = {};

export default Hub;

