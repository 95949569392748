import classNames from "classnames";
import { ReactComponent as BandIconSvg } from "./assets/Band.svg";
import React from "react";
import PropTypes from "prop-types";
import styles from "./BandIcon.module.scss";

const BandIcon = ({className}) => {
    return (
        <BandIconSvg className={classNames(styles.BandIcon, className)} fill="currentColor" stroke="currentColor"/>
    );
};

BandIcon.propTypes = {};

export default BandIcon;
