import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Label.module.scss';

const Label = ({ text, required, className }) => (
	<span className={classNames(className)}>
		{text} {required && '*'}
	</span>
);

Label.propTypes = {
	text: PropTypes.string.isRequired,
	required: PropTypes.bool,
	className: PropTypes.string,
};

export default Label;
