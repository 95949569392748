import HintRemote from "components/HintRemote/HintRemote.js";
import HomeButton from "components/Layout/HomeButton/HomeButton.js";
import LogoutButton from "components/Layout/LogoutButton/LogoutButton.js";
import { FINAL_SCREEN_ROUTE, HUB_ROUTE, START_OVER_SCREEN_ROUTE } from "constants/appRoutes.js";
import { AuthContext } from "contexts/AuthContext";
import { UserProgressContext } from "contexts/UserProgressContext";
import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import InviteButton from "views/InviteButton/InviteButton.js";
import styles from "./FixedContent.module.scss";

const FixedContent = props => {
        const { user } = useContext(AuthContext);
        const location = useLocation();
        const { morseSolved, hintRiddleSolved, introWatched, wasSolvedBefore } = useContext(UserProgressContext);

        const showHomeButton = useMemo(() => {
            return morseSolved && location.pathname !== HUB_ROUTE && location.pathname !== FINAL_SCREEN_ROUTE;
        }, [location.pathname, morseSolved]);

        const showRemote = useMemo(() => {
            if (location.pathname === START_OVER_SCREEN_ROUTE) {
                return false;
            }

            if (morseSolved) {
                if (!hintRiddleSolved && location.pathname === HUB_ROUTE) {
                    return true;
                } else {
                    return hintRiddleSolved;
                }
            }
            return false;
        }, [location.pathname, morseSolved, hintRiddleSolved]);

        const showInviteButton = useMemo(() => {
            return wasSolvedBefore;
        }, [wasSolvedBefore]);

        return (
            user && introWatched ? <div className={ styles.FixedContent }>
                { showHomeButton && <HomeButton/> }
                { showRemote && <HintRemote fadeOut={ location.pathname === FINAL_SCREEN_ROUTE }/> }
                { showInviteButton && <InviteButton/> }
                <LogoutButton/>
            </div> : ""
        );
    }
;

FixedContent.propTypes =
    {}
;

export default FixedContent;
