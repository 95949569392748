import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import styles from "./NumberWrapper.module.scss";

const NumberWrapper = ({color, children, small=false}) => {
    return (
        <div className={ classNames(styles.NumberWrapper, {[styles.Small]:small}) }>
            <div className={ styles[color] }>
                {children}
            </div>
        </div>
    );
};

NumberWrapper.propTypes = {};

export default NumberWrapper;
